import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3 ",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1)],1),_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" Eventjes om zeker te zijn ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Welke school zou je graag willen beheren? ")])]),_c(VCardText,[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.schools),function(school){return _c(VListItem,{key:school.id,on:{"click":function($event){return _vm.selectSchool(school)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(school.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(school.role)+" ")])],1)}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }