<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- Titel en logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- Tekstjes -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Eventjes om zeker te zijn
          </p>
          <p class="mb-2">
            Welke school zou je graag willen beheren?
          </p>
        </v-card-text>

        <!-- Schools -->
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="school in schools"
              :key="school.id"
              @click="selectSchool(school)"
            >
              <v-list-item-title>
                {{ school.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ school.role }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig'

// Icons
import {
mdiEyeOffOutline, mdiEyeOutline
} from '@mdi/js'

// Axios
import axios from '@axios'

export default {
  data: () => ({
    // themeConfig
    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,

    // Icons
    icons: {
      mdiEyeOffOutline,
      mdiEyeOutline,
    },

    schools: [],
  }),
  async mounted() {
    const connectedSchools = await axios.get('/account/me/school')
    console.log(connectedSchools.data.schools)
    this.schools = connectedSchools.data.schools
  },

  // oi

  methods: {
    async selectSchool(school) {
      localStorage.setItem('school', JSON.stringify(school))
      this.$router.push('/').catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
